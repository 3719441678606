<template>
  <div class="no-data">
    <!-- 数据为空 -->
    <template v-if="flagType === 'no-order'">
      <div class="empty-img no-order"></div>
      <div class="text">{{ $t("C.NoData") }}</div>
    </template>
  </div>
</template>

<script>
export default {
   props: {
    flagType: {
      type: String,
      default: 'no-order'
    },
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    // 去定制
    handleCustom () {
      this.$router.push('/custom')
    },
    // 添加收货地址
    handleAdd () {
      this.$emit('addAddr')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~common/styles/variable.styl'
@import '~common/styles/mixin.styl'

.no-data {
  position: absolute;
  width: 100%;
  padding: 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align(center);

  .empty-img {
    display: inline-block;
    width: 180px;
    height: 149px;

    &.no-order {
      height: 132px;
      bg-image('no-order');
      background-size: 100% 100%;

      .btn {
        margin-top: $spacing-lg;
      }
    }
  }

  .text {
    display: block;
    margin-top: $spacing-sm;
    font-size: $font-lg;
    color: $color-font-base;
    line-height: 45px;
  }

  .btn {
    margin: 60px auto;
    width: 100%;
    lineHeight(55px);
    border-radius: 55px;
    font-size: $font-lg;
    align(center);
    background-color: $color-main;
    vertical-align: middle;

    &.small {
      margin: 80px auto;
      width: 165px;
      lineHeight(45px);
    }
  }

  .btn-border {
    margin: 60px auto;
    width: 165px;
    lineHeight(45px);
    border-radius: $radius-sm;
    font-size: $font-lg;
    align(center);
    border: 2px solid $color-main;
    background-color: $color-bg-white;
    vertical-align: middle;
  }
}
</style>