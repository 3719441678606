export default {
	// 禁止页面滚动
	disableScroll() {
		document.body.addEventListener('touchmove', noScroll, { passive: false })
	},

	// 允许页面滚动
	allowScroll() {
		document.body.removeEventListener('touchmove', noScroll, { passive: false })
	}
}

const noScroll = (e) => {
	e.preventDefault()
}	
