<template>
	<div class="model-content">

		<h6 class="title">
			<span class="title-l" @click="handleCancel()">{{ $t("C.Cancel") }}</span>
			<span class="title-c">{{ $t("C.ChooseModel") }}</span>
			<span class="title-r" @click="handleConfirm()">{{ $t("C.Confirm") }}</span>
		</h6>

		<div class="brand-model-search-box">
			<el-input size="small" class="search-box-input" v-model.trim="content" clearable
				:placeholder="$t('C.SearchContent')" @input="handModelChange" @focus="getInputFocus">
			</el-input>
		</div>

		<!-- 原始的数据 -->
		<div class="origin-list" v-show="showOriginModel">
			<div class="model-l" ref="modelL">
				<p class="model-item" v-for="(item, index) in brandList" :key="index"
					:class="{ active: item.brandId === currBrandId }" @click="changeBrand(item, index)">
					<span>
						<i>{{ $i18n.locale === "zh-cn" ? item.brandName : item.brandNameEn }}</i>
					</span>
				</p>
			</div>
			<div class="model-r">
				<p class="model-item" v-for="(item, index) in modelList" :key="index"
					:class="{ active: item.modelId === currModelId }" @click="chooseModel(item)">
					<span>
						<i>{{ $i18n.locale === "zh-cn" ? item.modelNameCn : item.modelNameEn }}</i>
					</span>
				</p>
			</div>
		</div>

		<!-- 热门机型数据 -->
		<div class="hot-brand-model-list" v-show="showTopModel">
			<div class="hot-brand-box">
				<div class="hot-brand-title">
					{{ currBrandName }}
					<i class="icon icon-close" @click="closeTopModel"></i>
				</div>
				<div class="hot-brand-list">
					<div class="hot-brand-item" :class="item.modelId === currModelId ? 'active' : ''"
						v-for="(item, index) in popularModelList" :key="index" @click="chooseTopModel(item)">
						{{ $i18n.locale === "zh-cn" ? item.modelNameCn : item.modelNameEn }}
					</div>
				</div>
			</div>
		</div>

		<!-- 展示品牌和机型数据 -->
		<div class="hot-brand-model-list" v-show="showAllModel">
			<div class="hot-brand-box" v-for="(item, index) in queryModelList" :key="index">
				<div class="hot-brand-title">
					{{ $i18n.locale === "zh-cn" ? item.brandName : item.brandNameEn }}
				</div>
				<div class="hot-brand-list">
					<div class="hot-brand-item" :class="items.modelId === currModelId ? 'active' : ''"
						v-for="(items, index2) in item.modelList" :key="index2"
						@click="chooseTopModel({ ...items, 'brandId': item.brandId })">
						{{ $i18n.locale === "zh-cn" ? items.modelNameCn : items.modelNameEn }}
					</div>
				</div>
			</div>

			<!-- 无数据 -->
			<div v-show="!queryModelList">
				<NoData></NoData>
			</div>
		</div>

		<!-- 提示 -->
		<Loading v-show="isLoading" :message="message"></Loading>
	</div>
</template>

<script>
import api from "api/allApi";
import Loading from "components/loading/loading";
import NoData from "components/noData/noData";
import { debounce } from '@/utils/debounce';
export default {
	name: "Model",
	props: {
		brandList: {},
		modelList: {},
		topTenModelList: {},
		modelId: {
			type: Number(String),
			default: ""
		},
		brandId: {
			type: Number(String),
			default: ""
		}
	},
	data() {
		return {
			// Loading提示
			isLoading: false,
			message: "",

			// 1
			showOriginModel: true, // 展示初始机型
			currBrandId: 0, // 当前选择的品牌ID
			currBrandName: "", // 品牌名称
			curChooseBrand: {}, // 当前选中品牌{品牌id，品牌名称}

			// 2
			showTopModel: false, // 展示热门机型
			popularModelList: [], // 热门机型

			// 3
			content: '', // 搜索内容
			showAllModel: false, // 展示搜索的机型
			queryModelList: [], // 搜索机型

			// 4
			currModelId: 0, // 当前选择的机型ID
			curChooseModel: {} // 当前选中机型{机型id，机型名称}
		};
	},
	components: {
		Loading,
		NoData
	},
	watch: {
		topTenModelList(arr) {
			if (arr && arr.length > 0) {
				let popularModelList = [];
				arr.forEach(item => {
					popularModelList.push(item)
				});
				this.popularModelList = popularModelList;
			}
		},

		brandId(val) {
			this.currBrandId = val;
			this.brandList.forEach(item => {
				if (item.brandId === val) {
					this.currBrandName = localStorage.getItem("bLang") === "zh-cn" ? item.brandName : item
						.brandNameEn;
				}
			});
		},

		modelId(val) {
			this.currModelId = val;
		}
	},
	methods: {
		// 监听搜索
		handModelChange(val) {
			this.content = val;
			this.searchInput();
		},
		searchInput: debounce(function () {
			let val = this.content;
			if (val && val.length > 0) {
				this.content = val;
				this.$emit("setModelTop");
				// 将热门机型隐藏
				this.showTopModel = false;

				// 将搜索的数据机型展示出来
				this.showAllModel = true;

				// 调用接口加载数据
				this.isLoading = true;
				this.message = this.$t("C.Loading");
				this.startSearch(this.content);
			} else {
				// 将热门机型显示
				this.showTopModel = true;
				// 将搜索的数据机型隐藏 = 没输入内容自然是没有内容的
				this.showAllModel = false;
			}
		}, 500),

		// 监听搜索框聚焦
		getInputFocus() {
			this.$emit("setModelTop");
			// 将原始数据隐藏
			this.showOriginModel = false;
			var content = this.content;
			if (content == "" || content.length <= 0) {
				// 将热门机型数据显示
				this.showTopModel = true;
			}
		},

		// 搜索
		startSearch(val) {
			let content = val;
			let devSn = localStorage.getItem("devSn");
			this.$api.get(this, api.searchBrandModels, {
				deviceSn: devSn,
				modelName: content
			}).then((res) => {
				if (res.code === 0) {
					this.isLoading = false;
					this.queryModelList = [];
					this.queryModelList = res.data;

					this.$nextTick(() => {
						this.$refs.modelL.scrollTop = 100;
					});
				}
			});
		},

		// 切换品牌
		changeBrand(item) {
			this.currBrandId = item.brandId;
			this.$set(
				item,
				"brandName",
				localStorage.getItem("bLang") === "zh-cn" ? item.brandName : item.brandNameEn
			);
			this.$emit("change", item);
		},

		// 选择热门机型
		chooseTopModel(item) {
			// 将热门机型数据隐藏
			this.showTopModel = false;

			// 将原始数据显示
			this.showOriginModel = true;

			// 将搜索数据隐藏
			this.showAllModel = false;

			// 搜索置空
			this.content = "";

			// 搜索数据置空
			this.queryModelList = [];

			this.currModelId = item.modelId;
			this.currBrandId = item.brandId;
			this.$set(
				item,
				"modelName",
				localStorage.getItem("bLang") === "zh-cn" ? item.modelNameCn : item.modelNameEn
			);

			this.curChooseModel = item;
			this.$emit("confirm", this.curChooseModel, "top");
		},

		// 选择机型
		chooseModel(item) {
			this.$emit("setModelCenter");
			this.currModelId = item.modelId;
			this.$set(
				item,
				"modelName",
				localStorage.getItem("bLang") === "zh-cn" ? item.modelNameCn : item.modelNameEn
			);
			this.curChooseModel = item;
			let models = this.curChooseModel;
			this.$set(
				models,
				"modelName",
				localStorage.getItem("bLang") === "zh-cn" ? models.modelNameCn : models
					.modelNameEn
			);
			this.curChooseModel = models;
			this.$emit("changeModel", this.curChooseModel, "top");
		},

		// 取消
		handleCancel() {
			this.$emit("cancel");
			this.currModelId = this.modelId;
			// 搜索置空
			this.content = "";

			// 将热门机型数据隐藏
			this.showTopModel = false;

			// 将原始数据显示
			this.showOriginModel = true;
		},

		// 确定
		handleConfirm() {
			if (this.modelList && this.modelList.length > 0) {
				this.modelList.forEach((model) => {
					if (model.modelId === this.currModelId) {
						this.curChooseModel = model
					}
				});
			}
			let models = this.curChooseModel;
			this.$set(
				models,
				"modelName",
				localStorage.getItem("bLang") === "zh-cn" ? models.modelNameCn : models
					.modelNameEn
			);
			this.curChooseModel = models;
			this.$emit("confirm", this.curChooseModel, "origin");
		},

		// 关闭热门机型
		closeTopModel() {
			this.$emit("setModelCenter");
			// 将热门机型数据隐藏
			this.showTopModel = false;

			// 将原始数据显示
			this.showOriginModel = true;
		}
	}
};
</script>

<style lang="stylus" scoped>
	@import '~common/styles/variable.styl';
	@import '~common/styles/mixin.styl';

	.model-content {
		padding: 15px 15px 0;
		font-size: $font-base;
		line-height: 20px;

		.title {
			position: relative;
			align(center);

			.title-c {
				font-size: $font-lg;
			}

			.title-l {
				position: absolute;
				left: 0;
				color: $color-font-grey;
			}

			.title-r {
				position: absolute;
				right: 0;
				font-weight: 500;

			}
		}

		.brand-model-search-box {
			margin-top: 20px;

			.search-box-input {
				width: 100%;
				border-radius: 25px;

				>>>.el-input__inner {
					border-radius: 25px;
					height: 38px;
					color: #000000;
				}
			}
		}

		// 原始的数据
		.origin-list {
			height: 250px;
			margin-top: 15px;
			display: flex;
			overflow: auto;
			overflow-x: hidden;
			overflow-y: scroll;

			.model-item {
				line-height: 25px;
				overflow: hidden;
				text-overflow: ellipsis;

				&+.model-item {
					margin-top: $spacing-sm;
				}

				&.default {
					color: $color-font-grey;
				}

				&.active {
					span {
						position: relative;

						&::before {
							content: '';
							position: absolute;
							bottom: 0;
							left: -5px;
							width: calc(100% + 10px);
							height: 6px;
							background-color: $color-main;
							border-radius: 6px;
							z-index: 1;
						}
					}
				}

				i {
					position: relative;
					font-style: normal;
					z-index: 2;
				}

			}

			.model-l {
				position: relative;
				width: 30%;
				overflow: hidden;
				overflow-y: scroll;
				align(left);

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.model-r {
				position: relative;
				width: 100%;
				overflow: hidden;
				overflow-y: scroll;
				padding-left: 28px;
				border-left: 1px solid $color-border;
				align(left);

				.model-item {
					&.active {
						span {
							&::before {
								bottom: 2px;
								left: -3px;
								width: calc(100% + 5px);
							}
						}
					}
				}
			}
		}

		.hot-brand-model-list {
			height: calc(100%);
			margin-top: 10px;
			overflow: auto;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-bottom: 120px;

			&::-webkit-scrollbar {
				display: none;
			}

			.hot-brand-box {
				height: auto;

				.hot-brand-title {
					text-align: left;
					font-size: 17px;
					font-weight: bold;
					padding-top: 8px;
					display: flex;
					justify-content: space-between;
				}

				.hot-brand-list {
					height: auto;
					margin: 8px 0;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;


					.hot-brand-item {
						text-align: center;
						background-color: #f4f5f4;
						color: #000000;
						font-size: 12px;
						border-radius: 25px;
						margin: 5px;
						padding: 5px 10px;
					}

					.active {
						background-color: $color-bg-main-lighter;
						color: #ffffff;
					}
				}
			}
		}
	}
</style>
