<template>
	<div class="picture-con">
		<van-tabs class="picture-category" @click="chooseTab" v-model="curIndex" :ellipsis="false">
			<van-tab v-for="(category, index) in picCategory" :key="index" :title="category.gallerName"
				:swipe-threshold="3">
				<template #title>{{ $i18n.locale === "zh-cn" ? category.galleryName : category.galleryNameEn }}
					<p class="tag" v-if="category.galleryType && category.galleryType === 0 && curIndex === index">
						<span>{{ $t("C.Copyright") }}</span>
					</p>
				</template>
				<div class="pic-list" v-show="pictureList && pictureList.length > 0">
					<div class="img-list clearfix">
						<div class="img-wrap" v-for="(picture, picIndex) in pictureList" :key="picIndex"
							@click.stop="selectPic(picture)">
							<div class="img">
								<img v-lazy="picture.imgUrl + '?x-oss-process=image/resize,h_400,l_400'"
									:alt="picture.imgCN" />
							</div>
						</div>
					</div>
				</div>
				<div class="no-data-wrap" v-show="pictureList && pictureList.length <= 0">
					<NoData></NoData>
				</div>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import api from "api/allApi";
	// 组件
	import NoData from "components/noData/noData";
	export default {
		props: {
			picCategory: {
				type: Array,
				default: () => {}
			}
		},

		data() {
			return {
				curIndex: 0, // 当前图库索引
				galleryId: 0, // 当前图库ID
				galleryType: 0,
				deviceSn: '', // 设备
				modelId: '', // 机型
				pictureList: [] // 图片列表
			};
		},

		methods: {
			// 选择图库
			chooseTab(index) {
				this.curIndex = index;
				this.picCategory.forEach((item, indexx) => {
					if (index == indexx) {
						this.galleryId = item.id
						this.deviceSn = item.deviceSn
						this.modelId = item.modelId
						this.galleryType = item.galleryType
					}
				})
				this.$api.get(this, api.listPicture, {
					galleryId: this.galleryId,
					deviceSn: this.deviceSn,
					modelId: this.modelId,
					galleryType: this.galleryType
				}).then((res) => {
					if (res && res.code == 0) {
						this.pictureList = res.data;
						console.log(this.pictureList);
					} else {
						this.pictureList = [];
					}
				});
			},

			// 选择图片
			selectPic(item) {
				this.$emit("changeSelect", item.imgUrl, item.id, 0, 1);
			}
		},

		watch: {
			picCategory(data) {
				this.picCategory = data;
				if (this.picCategory.length > 0) {
					this.$api.get(this, api.listPicture, {
						galleryId: this.picCategory[0].id,
						deviceSn: this.picCategory[0].deviceSn,
						modelId: this.picCategory[0].modelId,
						galleryType: this.picCategory[0].galleryType
					}).then((res) => {
						if (res && res.code == 0) {
							this.pictureList = res.data;
						} else {
							this.pictureList = [];
						}
					});
				}
			}
		},

		components: {
			NoData
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~common/styles/variable.styl';
	@import '~common/styles/mixin.styl';

	.picture-con {
		position: absolute;
		padding: 0 $spacing-base;
		width: 100%;
		height: 100%;
		background-color: $color-bg-white;

		.picture-category {
			position: relative;
			width: 100%;
			height: 100%;

			&.child {
				>>>.van-tabs__wrap {
					height: 40px;

					.van-tabs__nav {
						padding: 5px 0 $spacing-sm;
						height: 24px;
					}

					.van-tab {
						font-size: $font-sm;

						&+.van-tab {
							margin-left: 25px;
						}
					}

					.van-tab--active {
						font-size: $font-xm;
					}
				}
			}
		}

		>>>.van-tabs__wrap {
			height: 53px;

			.van-tabs__nav {
				padding: $spacing-base 0 $spacing-sm;
				height: 24px;
			}

			.van-tab {
				position: relative;
				padding: 0;
				font-size: $font-xm;
				color: $color-font-grey;

				&+.van-tab {
					margin-left: 30px;
				}

				.tag {
					position: absolute;
					top: -15px;
					left: calc(50% + 24px);
					width: 32px;
					height: 18px;
					font-size: $font-sm;
					align(center);
					bg-image('banquan');
					background-size: 100% 100%;

					span {
						position: relative;
						top: -1px;
						display: block;
						//color: $color-font-base;
						color: #ffffff;
						transform: scale(0.8);
					}
				}
			}

			.van-tab--active {
				font-size: $font-lg;
				color: $color-font-base;
			}

			.van-tabs__line {
				position: absolute;
				bottom: 5px;
				width: 20px;
				height: 10px;
				bg-image('tab');
				background-color: transparent;
				background-size: 100% 100%;
			}
		}

		>>>.van-tab__pane {
			position: absolute;
			top: 53px;
			bottom: 30px;
			width: 100%;
		}

		.pic-list {
			position: relative;
			padding: $spacing-sm 7px;
			width: 100%;
			height: 100%;

			.img-list {
				position: absolute;
				top: 0;
				right: 7px;
				bottom: 0;
				left: 7px;
				padding-top: $spacing-base;
				padding-bottom: 3rem;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.img-wrap {
				float: left;
				width: calc((100% / 3));
				padding: 7px;

				.img {
					width: 100%;
					height: 100%;
					border-radius: $radius-base;
					overflow: hidden;
				}

				img {
					position: relative;
					left: 50%;
					min-width: 100%;
					height: 180px;
					transform: translateX(-50%);
				}
			}
		}
	}
</style>
